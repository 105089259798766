html { 
    height: 100% 
 }
 body { 
    margin: 0; 
    padding: 0; 
    height: 100% ;
 }
 .side-drawer { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    z-index: 5001;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    overflow-y: scroll;
 }
 .side-drawer.open {
    transform: translateX(0);
 }
 @media (max-width: 992px) {
   .side-drawer {
     width: 90%;
   }
 }