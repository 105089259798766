.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 5000;
    top: 0;
    right: 0;
    overflow-x: hidden;
    display: block;
  }
  